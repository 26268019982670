import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Aries.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => { 
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Aries Health
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/aries"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Aries</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Aries Health</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Aries Health</h1>
                <h2 className="text-sm md:text-base ml-4">Mar 21 - Apr 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-love");
                  }}>
                  <h5 className="font-semibold text-black">Aries Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-nature");
                  }}>
                  <h5 className="font-semibold text-black">Aries Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-man");
                  }}>
                  <h5 className="font-semibold text-black">Aries Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Aries Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-traits");
                  }}>
                  <h5 className="font-semibold text-black">Aries Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-facts");
                  }}>
                  <h5 className="font-semibold text-black">Aries Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            The Aries people have vibrant energy, contagious confidence, enthusiasm, strength, power, and stamina. Your health problems are mainly due to overstress work, long working hours, squeezing yourself to accomplish goals, angry impatience, and frustration. <br></br>
            <br></br> 
            The natives are usually passionate and possess a solid and rigid constitution. You would be restricted to bed when you are really unwell. Your response to delay has an adverse impact on your health. You have energy, confidence, and the tremendous power of speedy recovery. Your energy levels and moods fluctuate widely, which would be a severe concern for you. Though you are fitness conscious, you still need to take care of your diet for a healthy life. <br></br>
            <br></br>
            You should avoid caffeine items like coffee, sugar, and intoxicating drinks or aerated drinks as these substances tend to stress your ailments. You are adamant despite the tendency to get involved in accidents and all sorts of mishaps due to your intense activity. Get an easy remedial resolution online to overpower accidents and mishaps. <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Possible health concerns</p><br/>
            You are likely to fall target to chronic and lingering disorders. You are more sensitive to fuming fever, rage infections in the stomach and kidneys, strokes, high sugar levels, and blood pressure. You, however, are susceptible to stomach ailments, migraine headaches, and kidney infections. <br></br> 
            It would be oK if you took extra care of the head, stomach, and kidneys. Watching your diet would help as there are strong chances of indigestion and stomach disorders. It would be good if you guarded your eyesight, visited the dentist regularly, and treated your head cold seriously. It would benefit if you stayed away from alcohol to safeguard your kidneys and also to control explosive behavior and temperament due to Mars. You are likely to have pain in the knee cap and skin rashes.<br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Food for Aries</p><br/>
            You should consume a good amount of potassium phosphate in your diet to keep yourself out of depression. Potassium phosphate is good for the kidneys and liver. Food rich in these minerals are olives, pumpkin, apricots, broccoli, beans, tomatoes, brown rice, lentils, onions, walnuts, lettuce, cauliflower, cucumber, spinach, figs, apricots, and bananas. You should also contain dairy products in your healthy diet as they are rich in calcium and beneficial for teeth and bones. You should spend money on a juicer or blender to design vegetable juices and smoothies that are quick and nutritious for you. <br></br>
            <br></br>
            You belong to the fire feature, so you have to avoid spicy food to keep acidity at bay. Remember one thing Salt and liquor are your enemies. Too much consumption of Salt would bring hardships to bones and arteries. It would negatively impact your lymphatic and skeletal systems. You should avoid intoxicants as they would overstimulate and respond negatively and harmfully to your kidneys. <br></br> 
            <br></br>
            You should drink more water, around 3-4 litre in a day which is good for digestive problems and better functioning of the kidneys. You should take good sleep to be more comfortable and retrieve energy. 2024 Yearly Report gives you the best guidance to direct yourself in a way that can surely sweeten your life with certain conclusions related to your health. Get your Free Personalized 2024 Report! <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Aries Physical Appearance</p><br/>
            The natives have powerful features: a prominent chin, nose, and mouth with a full upper lip. You have wide-set eyes with well-marked eyebrows. Your style of speaking indicates your confidence. You generally have broad, muscular shoulders, and your body may little bend forward while walking. You lead your head while talking and always walk in a great hurry. Since you walk fast, you fall short of gracefulness. Your bone system is fine and healthy. Your poses reflect your self-confidence and ultimate ego, and bravery. You stand up straight and look directly in the eyes of the individuals unless you are extremely hurt or saddened. You have a moderate height, and your complexion is slightly tanned and more rugged than your average complexion. Your impression can be jarring, and you may have either one or two moles somewhere on your face. The look is usually bizarre, often sporty, enthusiastic, and unrefined. <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Aries Beauty</p><br/>
            You herald a fresh beginning, and the red color suits you perfectly. The color best matches your dynamic personality. It would be oK if you used this color more in your make-up and outfits as well as additions to enhance your features and beauty traits. You have a significant forehead with dark, striking, well-marked eyebrows praising your facial structure. The smoky shadiness and smudged broad eyeliner best suit your intense eye looks. You have the extravagance of well-defined cheekbones. <br></br> 
            <br></br>
            You will look alluring and graceful with a light golden shade highlighter and long earrings. The sign has importance on the head, so hats or headgear of any color and plan would suit your personality. Whether it is a last-minute investment or a carefully selected dress, it will look fantastic on an Aries. The same thing may look normal on anyone else, but you carry any outfit flawlessly and look stylish and elegant. <br></br>
            <br></br>
            Each individual has their own personality that drives him/her indifferent to the rest. What’s yours? Know with your personalized Janampatri. To learn more about it, Access your Free Janampatri here! <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
